import React, { useState, useEffect } from "react";
import { Button } from "antd";
import banner from "../../assets/images/banner/poster_v6.png";

const AnnouncementBanner = () => {
  const [isVisible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div
      className="banner-box "
      style={{ display: `${isVisible === true ? "flex" : "none"}` }}
    >
      <Button className="close-button" onClick={handleClose}>
        x
      </Button>
      <img src={banner} alt="Announcement" style={styles.image} />
      <a className="reg-button" href="https://forms.office.com/r/H2yvkZGwmH">
        Бүртгүүлэх
      </a>
    </div>
  );
};

const styles = {
  image: {
    width: "100%",
    height: "100%",
    // maxHeight: "650px",
  },
  closeButton: {
    // marginLeft: "auto",
  },
};

export default AnnouncementBanner;

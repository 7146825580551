import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import Header from '../../Header';
import { useNavigate } from 'react-router-dom';
import callApi from '../../../services/api';

const ReservationDetails = () => {
  const options = [];
  const [teachers, setTeachers] = useState([])
  for (let i = 10; i < 18; i++) {
    options.push({
      value: i + ':00',
      label: i + ':00',
    });
  }
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await callApi(`/teachers`, {
          method: 'GET',
        });
        setTeachers(res)
        console.log('teachers: ', teachers);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    fetchData();
  }, [teachers]);

  return (
    <>
      <Header />
      {/* <PageBanner title={"Цаг баталгаажуулах"}  /> */}
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          {teachers.map((teacher, index) => (
            <Card key={index} style={{ marginTop: 20 }}>
              <Row style={{ marginLeft: 10 }}>
                <img
                  src={teacher.img}
                  alt=""
                  className="w-\[46px\]  h-\[46px\] object-cover rounded-full"
                  width="120" height="20"
                />
                <Col style={{ marginLeft: 20, width: '80%' }} >
                  <div className="lg:text-2xl text-1xl mb-1 font-bold">{teacher.userData.firstname}</div>
                  <div className="mini-title">{teacher.title}</div>
                  <div className="font-gilroy font-medium text-gray text-lg leading-[27px]" style={{ color: "#6b7280" }}>25 жил ажилласан</div>
                  <div className=" mini-title" style={{ color: "#6b7280" }}>{teacher.price}₮</div>
                  <div className="mini-title" style={{ color: "#6b7280" }}>58 зөвлөгөө өгсөн</div>
                </Col>
                <Col className='flex justify-end' style={{ height: 60 }}>
                  <button onClick={() => navigate('confirm', { state: { teacher: teacher } })} className="btn btn-primary">
                    Цаг захиалах
                  </button>
                </Col>
              </Row>
            </Card>
          ))}

        </div>
      </div>
    </>
  );
};

export default ReservationDetails;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,600i,700,700i);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-area {
  background: #fff;
}
.hero-text {
  margin-top: 200px;
}
.hero-text h1 {
  color: #35ac39;
  font-size: 60px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}
.hero-text h3 {
  margin-bottom: 60px;
  font-weight: 600;
  color: #333;
  position: relative;
}
.back-home {
  background: #35ac39;
  border: 2px solid #35ac39;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  position: relative;
  margin: 15px 15px 0;
  padding: 12px 36px;
  text-transform: capitalize;
  transition: all 0.2s ease 0s;
  -webkit-text-decoration: navajowhite;
          text-decoration: navajowhite;
}
.back-home:hover,
.back-home:focus {
  background: #4328b7;
  color: #fff;
  border: 2px solid #4328b7;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/thanksPage.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,4BAA4B;EAC5B,oCAA4B;UAA5B,4BAA4B;AAC9B;AACA;;EAEE,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,600i,700,700i\");\r\n\r\n.welcome-area {\r\n  background: #fff;\r\n}\r\n.hero-text {\r\n  margin-top: 200px;\r\n}\r\n.hero-text h1 {\r\n  color: #35ac39;\r\n  font-size: 60px;\r\n  font-weight: 500;\r\n  line-height: 70px;\r\n  margin-bottom: 20px;\r\n  position: relative;\r\n  text-transform: capitalize;\r\n}\r\n.hero-text h3 {\r\n  margin-bottom: 60px;\r\n  font-weight: 600;\r\n  color: #333;\r\n  position: relative;\r\n}\r\n.back-home {\r\n  background: #35ac39;\r\n  border: 2px solid #35ac39;\r\n  border-radius: 500px;\r\n  color: #fff;\r\n  font-weight: 600;\r\n  position: relative;\r\n  margin: 15px 15px 0;\r\n  padding: 12px 36px;\r\n  text-transform: capitalize;\r\n  transition: all 0.2s ease 0s;\r\n  text-decoration: navajowhite;\r\n}\r\n.back-home:hover,\r\n.back-home:focus {\r\n  background: #4328b7;\r\n  color: #fff;\r\n  border: 2px solid #4328b7;\r\n  text-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Footer from "../../Footer";
// import BlogPost2 from "../../ad/BlogPost2";
import Header from "../../Header";
import { invest } from "../../../constant/images";

const SingleBlog5 = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: 150 }}></div>
      {/* <PageBanner title={"Нийтлэл"} pageName="Нийтлэл" /> */}
      <div className="container" style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: 1000,
            margin: "0px auto",
          }}
        >
          <div style={{}}>
            <div
              style={{
                fontSize: 40,
                lineHeight: 1,
                fontFamily: "Roboto",
                fontWeight: 700,
                marginTop: 30,
                // textAlign: "justify",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                letterSpacing: 2,
              }}
            >
              Гадаадын хөрөнгө оруулалтын татварын орчин
            </div>
            <div
              style={{
                marginTop: 40,
                fontSize: 15,
                fontFamily: "Roboto",
                textAlign: "justify",
                color: "#3c414e",
              }}
            >
              <p
                style={{
                  marginBottom: 10,
                  textAlign: "justify",
                }}
              >
                Монгол Улсын Хөрөнгө оруулалтын тухай хууль нь Монгол Улсын
                нутаг дэвсгэрт хөрөнгө оруулагчийн хууль ёсны эрх, ашиг
                сонирхлыг хамгаалах, хөрөнгө оруулалтын эрх зүйн нийтлэг
                баталгааг тогтоох, хөрөнгө оруулалтыг дэмжих, татварын орчныг
                тогтворжуулах, хөрөнгө оруулалтын талаар төрийн байгууллагын эрх
                хэмжээ, хөрөнгө оруулагчийн эрх, үүргийг тодорхойлох болон
                хөрөнгө оруулалттай холбоотой бусад харилцааг зохицуулдаг.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Уг хуулинд “гадаадын хөрөнгө оруулалттай аж ахуйн нэгж" гэж
                Монгол Улсын хууль тогтоомжийн дагуу байгуулагдсан, хуулийн
                этгээдийн нийт гаргасан хувьцааны 25 буюу түүнээс дээш хувийг
                гадаадын хөрөнгө оруулагч эзэмшиж байгаа бөгөөд гадаадын хөрөнгө
                оруулагч тус бүрийн оруулсан хөрөнгө оруулалтын хэмжээ нь 100
                мянган америк доллар буюу түүнтэй тэнцэх хэмжээний төгрөгөөс
                дээш байх аж ахуйн нэгж байна гэж заасан байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Гадаадын болон дотоодын хөрөнгө оруулагч нь Компанийн тухай,
                Хуулийн этгээдийн улсын бүртгэлийн тухай хууль болон холбогдох
                бусад хууль тогтоомжийн дагуу улсын бүртгэлд бүртгүүлснээр
                Монгол Улсад үйл ажиллагаа эрхэлнэ.
              </p>
              <div
                style={{
                  display: "flex",
                  margin: "0px auto",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <img src={invest} alt="sss" />
              </div>
              <p>
                Хөрөнгө оруулалтыг Монгол Улсад дараах хэлбэрээр хэрэгжүүлнэ:
              </p>
              <ul style={{ marginLeft: 20 }}>
                <li>
                  - хөрөнгө оруулагч дангаараа болон бусад хөрөнгө оруулагчтай
                  хамтран аж ахуйн нэгж байгуулах;
                </li>
                <li>
                  - хөрөнгө оруулагч хувьцаа, өрийн бичиг, бусад төрлийн үнэт
                  цаасыг худалдан авах;
                </li>
              </ul>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Хөрөнгө оруулагч нь Монгол Улсын нутаг дэвсгэрт татвар төлөх
                үүргээ бүрэн биелүүлсний үндсэн дээр доор дурдсан өөрт ногдох
                хөрөнгө, орлогыг гадаад улсад саадгүй гаргах эрхтэй:
              </p>
              <ul style={{ marginLeft: 20 }}>
                <li>- үйл ажиллагаанаас олсон ашиг, ногдол ашиг;</li>
                <li>
                  - оюуны өмчийг бусдад ашиглуулсан эрхийн шимтгэл, ажил
                  гүйцэтгэсэн, үйлчилгээ үзүүлсний төлбөр;
                </li>
                <li>
                  - гадаад улсаас олгосон зээлийн үндсэн төлбөр болон хүү;
                </li>
                <li>
                  - аж ахуйн нэгжийг татан буулгасны дараа өөрт ногдох хөрөнгө;
                </li>
                <li>
                  - Хөрөнгө оруулагч нь хөрөнгө, орлогыг мөнгөн хөрөнгө
                  хэлбэрээр гадаад улсад гаргахдаа өөрийн сонгосон, олон улсад
                  чөлөөтэй хөрвөх гадаад валютад хөрвүүлж, шилжүүлэх эрхтэй.
                </li>
                <li></li>
              </ul>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Татварын хөрөнгө оруулалтын дэмжлэг нь:
              </p>
              <ul style={{ marginLeft: 20 }}>
                <li>
                  - албан татвар ногдох орлогоос хасагдах элэгдлийн зардлыг
                  түргэвчилсэн аргаар тооцох;
                </li>
                <li>
                  - албан татвар ногдох орлогоос хасагдах алдагдлыг ирээдүйд
                  шилжүүлэн тооцох;
                </li>
                <li>
                  - ажилтны сургалтын зардлыг татвар ногдуулах орлогоос хасч
                  тооцох.
                </li>
                <li>
                  - Монгол Улсад хөрөнгө оруулалт хийсэн гадаадын хөрөнгө
                  оруулагч, түүний гэр бүлд Монгол Улсад зорчих олон удаагийн
                  орж гарах виз болон байнга оршин суух зөвшөөрлийг холбогдох
                  хууль тогтоомжийн дагуу олгох;
                </li>
              </ul>
              <div
                style={{ fontWeight: "bold", marginTop: 30, marginBottom: 30 }}
              >
                Татвар хэзээ төлөх вэ?{" "}
              </div>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Гадаад улсаас Монгол Улсад хөрөнгө оруулалт мөнгөн болон бусад
                хэлбэрээр оруулж ирж байгаа тохиолдолд тухай хөрөнгө оруулалтыг
                нотлох гэрээ, хэлцэл бусад бичиг баримт хангалттай байгаа
                тохиолдолд ямар нэгэн татвар төлөхгүй.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Харилцах дансаар оруулж ирсэн мөнгөн хөрөнгө нь борлуулалтын
                орлого биш гэдгийг нотлох баримтууд шаардлагатай.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Бараа материалаар хөрөнгө оруулалт хийж байгаа тохиолдолд гааль
                болон нэмэгдсэн өртгийн албан татвар төлнө. Тухайн бараа
                материалыг данс бүртгэлдээ үнэн зөв тусгасан байх шаардлагатай.
                Харин бараа материалыг зарж борлуулсан тохиолдолд аж ахуйн
                нэгжийн орлогын албан татвар болон нэмэгдсэн өртгийн албан
                татварын босго давсан тохиолдолд нэмэгдсэн өртгийн албан
                татварыг төлнө.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Цаашид хөрөнгө оруулалтыг ямар хэлбэрээр оруулахаас хамаараад
                дараа дараагийн санхүү бүртгэл, татварын асуудлууд үүснэ.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Гадаадын хөрөнгө оруулалтыг үнэн зөв бүртгэж тайлагнах нь
                хөрөнгө оруулагчдын ашигт ажиллагааг нэмэгдүүлэх цаашлаад улс
                орны эдийн засагт чухал хувь нэмэр оруулах ач холбогдолтой юмаа.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleBlog5;

import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import RegisterDetails from "./RegisterDetails";

const Register = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Login"} pageName={"Бүртгүүлэх"} />
      <RegisterDetails />
      <Footer />
    </>
  );
};

export default Register;

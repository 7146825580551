import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import { news1 } from "../../../constant/images";

const SingleBlog4 = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: 150 }}></div>
      {/* <PageBanner title={"Нийтлэл"} pageName="Нийтлэл" /> */}
      <div className="container" style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: 1000,
            margin: "0px auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 80 }}>
            <div
              style={{
                fontSize: 40,
                lineHeight: 1,
                fontFamily: "Roboto",
                fontWeight: 700,
                marginTop: 30,
                // textAlign: "justify",
                justifyContent: "center",
                // alignItems: "center",
                display: "flex",
                // letterSpacing: 2,
                marginRight: 40,
              }}
            >
              Татварын{" "}
              <span
                className="shape-bg"
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                {" "}
                зөвлөх{" "}
              </span>{" "}
              үйлчилгээний талаар
            </div>
            <div
              style={{
                marginTop: 40,
                fontSize: 15,
                fontFamily: "Roboto",
                textAlign: "justify",
                color: "#3c414e",
                transition: "font-size .2s ease-in-out ",
                marginRight: 40,
              }}
            >
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Монгол Улсад Татварын зөвлөх үйлчилгээ үүсэж хөгжөөд 20 жил,
                Татварын мэргэшсэн зөвлөх үйлчилгээний тухай хууль батлагдаад 10
                гаруй жил болж байна. Гэсэн хэдий ч татварын мэргэшсэн зөвлөх
                үйлчилгээний талаар иргэд болон аж ахуйн нэгж байгууллагын
                удирдлагууд, олон нийт төдийлөн хангалттай хэмжээний ойлголтгүй,
                татварын мэргэшсэн зөвлөхүүдийг нягтлан бодогчтой адилтган үзэх
                хандлагатай байсаар байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Татварын мэргэшсэн зөвлөх үйлчилгээ гэдэг нь дэлхийн олон улс
                оронд татварын тооцоолол, тайлагнал, хууль тогтоомжийн талаар
                зөвлөгөө өгөх, хуулийн хэрэгжилтийг хангуулахад татварын алба
                болон татвар төлөгчдийн дунд холбогч нь болон нэг талаасаа
                татвар төлөгчдийн хууль ёсны эрх ашгийг хамгаалан нөгөө талаасаа
                улс орны төсвийн орлогыг бүрдүүлэхэд хувь нэмрээ оруулан
                ажилладаг орчин үеийн бизнесийн чиг хандлага, нийгмийн
                шаардлагын үндсэн дээр үүсэн бий болсон ажил үйлчилгээ юмаа.
                Тиймдээ ч Европын холбоо, Ази Номхон далайн орнууд гэх мэт бүс
                нутгийг хамарсан татварын мэргэшсэн зөвлөхүүдийн холбоодууд
                үүсгэн байгуулагдаж хөгжин тэлж, үйл ажиллагаа нь өргөжсөөр
                байна.
              </p>
              <img src={news1} alt="sss" />
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Сүүлийн жилүүдэд татварын багц хуулийн шинэчлэлтэй холбоотойгоор
                татварын тайланд олон төрлийн мэдээллүүдийг тусгах болсон мөн
                үндэстэн дамнасан ажил гүйлгээ их хийгдэж, бизнес эрхлэгчид
                дотоодын төдийгүй гадаадын зах зээлд бүтээгдэхүүн үйлчилгээгээ
                нийлүүлэх, гаднаас ажил үйлчилгээ авах зэрэг өөрийн үйл
                ажиллагаандаа татвараа зөв зохистой хуулийн дагуу ногдуулах
                асуудлуудыг аж ахуйн нэгжийн нягтлан бодогчид дангаараа
                шийдвэрлэх боломжгүй бусдын туслалцаа, зөвлөгөө авах хэрэгцээ
                шаардлагууд улам бүр нэмэгдсээр л байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Монгол Улсын хэмжээнд татварын мэргэшсэн зөвлөх үйлчилгээ
                үзүүлэх тусгай зөвшөөрөлтэй 40 орчим аж ахуйн нэгж байгаа нь 270
                000 аж ахуйн нэгжүүдэд төдийлөн хүрч үйлчлэх боломжгүй тул ихэнх
                татвар төлөгчид татварын эрсдэлтэй нөхцөл байдалд үйл ажиллагаа
                явуулж байна гэж харагдахаар байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Өнгөрсөн 2023 оны татварын хяналт шалгалтын байдлаас харвал
                хэдийгээр эрсдэлд суурилсан хяналт шалгалт хийгдэж байгаа боловч
                уул уурхайн салбарт үйл ажиллагаа эрхлэгчдэд ногдуулсан
                төлбөрийн хэмжээ хамгийн их байгаа нь энэ салбарт ашигт
                малтмалын нөөц ашигласны төлбөрийн тооцооллыг үнэн зөв хийж
                тайлагнадаг мэргэшсэн зөвлөхүүд шаардлагатай болохыг харуулж
                байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Иймд татварын зөвлөх үйлчилгээг хэрэгцээтэй цаг үедээ авах нь
                ирээдүйд учирч болох татварын эрсдэлээс урьдчилан сэргийлж,
                бизнесийн бодит үнэ цэнэ, ашиг орлогоо нэмэгдүүлэн,
                байгууллагынхаа стратегийн зорилго зорилтондоо амжилттай хүрэх
                боломжийг бүрдүүлдэг чухал ач холбогдолтой юмаа.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleBlog4;

import React from "react";
import Footer from "../../Footer";
import PageBanner from "../../PageBanner";
// import BlogPost2 from "../../ad/BlogPost2";
import Header from "../../Header";

const SingleBlog2 = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Нийтлэл"} pageName="Нийтлэл" />
      {/* <BlogPost2 /> */}
      <Footer />
    </>
  );
};

export default SingleBlog2;

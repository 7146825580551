// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-explore:hover > ul {
  display: flex;
}
.social-explore > ul {
  display: none;
}
.social-explore:hover > button {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":[".social-explore:hover > ul {\r\n  display: flex;\r\n}\r\n.social-explore > ul {\r\n  display: none;\r\n}\r\n.social-explore:hover > button {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// api.js

const BASE_URL = "http://103.50.206.233:3305/v1";
// const BASE_URL = "http://localhost:3305/v1";

async function callApi(endpoint, options = {}) {
  const headers = {
    "Content-Type": "application/json",
  };

  // Retrieve API token from localStorage
  const token = localStorage.getItem("accessToken");
  console.log("token: ", token);
  // Add token to headers if available
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const requestOptions = {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  };

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error calling API: ${error}`);
  }
}

export default callApi;

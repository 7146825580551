import React from "react";
import Footer from "../../Footer";
import PageBanner from "../../PageBanner";
import BlogPost3 from "./BlogPost3";

const SingleBlog3 = () => {
  return (
    <>
      {/* <Header /> */}
      <PageBanner title={"Нийтлэл"} pageName="Нийтлэл" />
      <BlogPost3 />
      <Footer />
    </>
  );
};

export default SingleBlog3;

import React from "react";
import Footer from "../Footer";
import Header from "../Header";
// import Team from "../Team";
import Intro from "../Intro";
import Team from "../Team";
import News from "../News";

const AboutOne = () => {
  return (
    <>
      <div style={{ marginTop: 200 }}>
        <Header />
        {/* <PageBanner title={"Бидний тухай"} pageTitle="Бидний тухай" /> */}
        <Intro></Intro>
        <News></News>
        <Team />
        {/* <About /> */}
        {/* <Feature /> */}
        {/* <Counter /> */}
        {/* <Testimonials /> */}
        <Footer />
      </div>
    </>
  );
};

export default AboutOne;

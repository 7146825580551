import React, { useState } from "react";
import {
  Select,
  Space,
  Radio,
  Card,
  Col,
  Row,
  DatePicker,
  notification,
} from "antd";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import callApi from "../../../services/api";

const ReservationConfirm = (props) => {
  const location = useLocation();
  const { teacher } = location.state || {};
  const [selectedTime, setSelectedTime] = useState(null);
  const [times, setTimes] = useState([]);
  const [rawTimes, setRawTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [payment, setPayment] = useState(0);
  const teacherPrice = teacher.price || 0;
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("online");
  const [isEditMode] = useState(false);
  const [types] = useState([
    { value: 1, label: "Татвар" },
    { value: 2, label: "НӨАТ" },
    { value: 3, label: "Бусад" },
  ]);
  const locations = [
    { label: "Online", value: "online" },
    { label: "Onsite", value: "onsite" },
  ];

  const storedUser = localStorage.getItem("user");
  const userData = JSON.parse(storedUser);
  const userId = userData.user_id;
  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/reservation", { replace: true });
  };

  const openNotification = () => {
    notification.success({
      message: "Амжилттай",
      description: "Цаг захиалга амжилттай хийгдлээ.",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  const handleChange = (value) => {
    setSelectedTime(value);
    const payment = 1 * teacherPrice;
    setPayment(payment);
  };

  const onChangeLocation = ({ target: { value } }) => {
    setSelectedLocation(value);
    callTime();
  };
  const handleChangeType = (e) => {
    setSelectedType(e);
  };
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const callTime = async () => {
    const res = await callApi(`/times/${props.device.id}`, {
      method: "GET",
      body: JSON.stringify({
        location: selectedLocation,
        teacherId: teacher.teacher_id,
      }),
    });
    setRawTimes(res.data);
    console.log("called times: ", res);
    await new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const onChangeDate = async (date, dateString) => {
    setSelectedDate(date);
    callTime();
    const filteredTimes = rawTimes.filter((item) => {
      const dateObject = new Date(item.advice_date);
      const formattedDate = dateObject.toISOString().slice(0, 10);
      return formattedDate === dateString;
    });
    const timeOption = [];
    filteredTimes.map((param) =>
      timeOption.push({
        value: param?.id,
        label: param?.time,
      })
    );
    setTimes(timeOption);
  };

  const showTime = (id) => {
    const system = times.find((sys) => sys.value === id);
    return system ? system.label : "";
  };

  const showType = (id) => {
    const type = locations.find((sys) => sys.value === id);
    return type ? type.label : "";
  };

  const selectedTypeJs = JSON.stringify(selectedType);
  const reserveTime = async () => {
    try {
      if (selectedDate && selectedTime && selectedTypeJs) {
        const reservationData = {
          user_id: userId,
          teacher_id: teacher.teacher_id,
          booking_date: selectedDate,
          booking_time: selectedTime,
          type_choices: selectedTypeJs,
          status: "created",
        };
        console.log("reservationData: ", reservationData);
        if (isEditMode) {
          const res = await callApi(`/reservation/${props.device.id}`, {
            method: "PUT",
            body: JSON.stringify(reservationData),
          });
          if (res) {
            navigate("/reservation", { replace: true });
            openNotification();
          }
        } else {
          const res = await callApi(`/reservation`, {
            method: "POST",
            body: JSON.stringify(reservationData),
          });
          if (res) {
            navigate("/reservation", { replace: true });
            openNotification();
          }
        }
      } else {
        notification.error({ message: "Мэдээллээ бүрэн бөглөнө үү." });
      }
      // props.updateList();
    } catch (error) {
      console.error("Error during reservation:", error);
    }
  };

  return (
    <>
      <Header />
      <PageBanner title={"Зөвлөгөө авах"} />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container" style={{ width: 1000 }}>
          <Row style={{ marginLeft: 10, marginTop: 30 }}>
            <img
              src={teacher.img}
              alt=""
              className="w-\[46px\]  h-\[46px\] object-cover rounded-full"
              width="120"
              height="20"
            />
            <Col style={{ marginLeft: 20 }}>
              <div className=" lg:text-2xl text-1xl mb-1 font-bold">
                {teacher.userData.firstname}
              </div>
              <div className="mini-title">{teacher.title}</div>
            </Col>
          </Row>
          <Space direction="vertical" style={{ marginTop: 20 }}>
            <Radio.Group
              size="large"
              options={locations}
              onChange={onChangeLocation}
              value={selectedLocation}
              optionType="button"
            />
          </Space>
          <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
            <Select
              mode="multiple"
              size="large"
              placeholder="Төрлөө сонгоно уу"
              onChange={handleChangeType}
              style={{ width: "100%", fontSize: "1.125rem" }}
              options={types}
            />
          </Space>
          <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
            <Row direction="vertical" style={{ width: "100%" }}>
              <Col style={{ width: "40%" }}>
                <DatePicker
                  placeholder="Өдрөө сонгоно уу"
                  size="large"
                  style={{ width: "90%" }}
                  disabledDate={disabledDate}
                  onChange={onChangeDate}
                />
              </Col>
              <Col style={{ width: "60%" }}>
                <Select
                  mode="single"
                  size="large"
                  placeholder="Цагаа сонгоно уу"
                  onChange={handleChange}
                  style={{ width: "100%", fontSize: "1.125rem" }}
                  options={times}
                />
              </Col>
            </Row>
          </Space>
          <Card
            title="Захиалгын мэдээлэл"
            direction="vertical"
            style={{ width: "100%", marginBottom: 20, marginTop: 20 }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <span style={{ fontSize: "1.125rem" }} className="mini-title">
                Төрөл:
              </span>
              <p style={{ fontSize: "1.125rem" }}>
                {showType(selectedLocation)}
              </p>
            </Space>
            <Space direction="vertical" style={{ width: "100%" }}>
              <span style={{ fontSize: "1.125rem" }} className="mini-title">
                Сонгосон өдөр:
              </span>
              <p style={{ fontSize: "1.125rem" }}>
                {selectedDate ? selectedDate.format("YYYY-MM-DD") : ""}
              </p>
            </Space>
            <Space direction="vertical" style={{ width: "100%" }}>
              <span style={{ fontSize: "1.125rem" }} className="mini-title">
                Сонгосон цагууд:
              </span>
              <p style={{ fontSize: "1.125rem" }}> {showTime(selectedTime)}</p>
            </Space>
            <span className="mini-title" style={{ fontSize: "1.125rem" }}>
              Нийт төлбөр:
            </span>
            <p style={{ fontSize: "1.125rem" }}>{payment}₮</p>
          </Card>
          <div className="flex justify-center">
            <button
              onClick={closeModal}
              className=" btn btn-pink"
              style={{
                border: "1px solid #808080",
                marginRight: 20,
                cursor: "pointer",
              }}
            >
              Буцах
            </button>
            <button
              onClick={reserveTime}
              className=" btn btn-primary"
              style={{ cursor: "pointer" }}
            >
              Баталгаажуулах
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationConfirm;

import React from "react";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import Footer from "../../Footer";
// import BlogPost from "../../ad/BlogPost";

const SingleBlog = () => {
  return (
    <>
      <Header />
      <PageBanner pageName="Нийтлэл" />
      {/* <BlogPost /> */}
      <Footer />
    </>
  );
};

export default SingleBlog;

import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import ReservationDetails from "./ReservationDetails";

const Reservation = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Зөвлөгөө авах"} pageName={"Зөвлөгөө авах"} />
      <ReservationDetails />
      <Footer />
    </>
  );
};

export default Reservation;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { law, consulting, search } from "../../../constant/images";
import ContactForm from "../../ContactForm";
// import BlogCategories from "./BlogCategories";
// import RelatedCourses from "./RelatedCourses";
// import PopularTags from "./PopularTags";
// import BlogTitle from "./BlogTitle";

const BlogPost3 = () => {
  return (
    <div className="nav-tab-wrapper tabs  section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="lg:col-span-8 col-span-12">
            <div className="bg-[#F8F8F8] rounded-md">
              <img src={law} alt="" style={{ width: "100%", padding: 20 }} />
              <div className="px-10 pb-10">
                {/* <BlogTitle></BlogTitle> */}
                <h3>
                  Аялал жуулчлалын салбарын “бэрхшээлийн тойрог” буюу Хуулийн
                  ЗОХИЦУУЛАЛТЫН алдаа{" "}
                </h3>
                <p
                  className="mt-6 space-x-2"
                  style={{
                    lineHeight: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Тайлангийн хугацаа дуусахаас бараг сарын өмнө бид аялал
                  жуулчлалын салбарт үйл ажиллагаа эрхлэгчдэд сургалт хийсэн юм.
                </p>
                <p
                  className="mt-6 space-x-2"
                  style={{
                    lineHeight: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Аялал жуулчлалын тухай хууль 2023 оны 5 дугаар сарын 4-ны өдөр
                  шинэчлэгдэн батлагджээ. Тухайн салбарт гацаад байсан олон
                  асуудлыг шийдвэрлэсэн, бас хөгжихөд нь түлхэц болох олон
                  боломж олгосон хууль батлагдсанд энэ салбарт зүтгэж буй аж
                  ахуйн нэгж, хувь хүн бүгд л баяртай байсан болов уу.
                </p>
                <img
                  src={consulting}
                  alt=""
                  width="80%"
                  style={{ marginLeft: "10%", marginTop: 20, marginBottom: 20 }}
                />
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Гэхдээ энэ хууль батлагдсан цагаасаа л салбарт ажиллаж буй
                  төрийн бус байгууллага, холбоод аж ахуйн нэгжүүдийн хооронд
                  зөрчил үүсгэсэн, татварын хөнгөлөлтийг хэрхэн эдлэх талаар
                  одоо ч асуудал дагуулсаар байна. Санамсаргүй тэр маргаан дунд
                  нь сургалт хийж байгаад бид яваад оржээ. Маргааны сэдэв юунаас
                  эхлэлтэйг бид мэдэхгүй. Аялал жуулчлалын холбооноос манай
                  холбоонд хүсэлт тавьсны дагуу сургалт хийгдсэн юмаа. Хуулинд
                  болон хэрэгжилтэнд асуудал их байгаа нь нүдний өмнө харагдаж
                  байсан ч дотоод нарийн зөрчлийг нь огт мэдэхгүй тул аль нэг
                  талын байр суурийг хамгаалах зорилгоор энэ нийтлэл
                  бичигдээгүй. Харин хэрэгжилт нь хамгийн түрүүнд нөлөө үзүүлэх
                  аялал жуулчлалын салбар болон энэ салбарт үйл ажиллагаа
                  эрхлэгч татвар төлөгчдөд үүсч буй асуудлын зангилааг тайлахыг
                  хичээн энэхүү нийтлэлийг бичлээ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Сургалт хийхээсээ өмнө тэдний өмнө тулгарч буй асуудал юу
                  байна гэж хайв.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Аялал жуулчлалын шинэчлэн баталсан хуулийг өмнөх хуультай нь
                  харьцуулж үзэв. Дараа нь холбогдох татварын хуулиудыг хэрхэн
                  өөрчилж, тухайн өөрчлөлтэй холбоотой татварын тайлангийн
                  маягт, цахим татварын системд өөрчлөлт оруулсан эсэхийг
                  хайлаа.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Шинэчлэн батлагдсан аялал жуулчлалын хууль болон татвартай
                  холбогдох заалт нь 2 хуулинд тусгагдахдаа “алдаа”- тай
                  гэхээсээ илүүтэй зөв ЗОХИЦУУЛАГДААГҮЙгээс салбарт үйл
                  ажиллагаа эрхлэгчдийн дунд маргаан үүсгэж ойлгомжгүй байдал
                  бий болгожээ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Аялал жуулчлалын шинэчлэн батлагдсан хуулинд байгаа “алдаа” юу
                  вэ?
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  1. Энэ хууль хэзээнээс эхлэн хэрэгжиж эхлэх тухай заалт алга.
                  Уг нь чухал заалт.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Гэхдээ ингэж батласан хуулийн хэрэгжиж эхлэх хугацааг Төрийн
                  мэдээлэл эмхэтгэлд хэвлэсэн өдрөөр тооцох тухай Дээд шүүхийн
                  тайлбар байдаг. Төрийн мэдээлэл эмхэтгэлийн 2023 оны 6 дугаар
                  сарын 23-ний 24 (1272) дугаарт энэ хууль хэвлэгдсэн байна.
                  Тэгэхээр 6 дугаар сарын 23-ний өдрөөс хууль хүчин төгөлдөр
                  болж байна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Хуулийн хэрэгжиж эхлэх хугацаа нь яагаад энэ удаад илүү ач
                  холбогдолтой байсан бэ гэвэл аж ахуйн нэгжийн орлогын албан
                  татварын тухай хуулинд тур операторын борлуулалтын “орлогод
                  хөнгөлөлт” эдлүүлэх заалт нь хууль хүчин төгөлдөр болсон
                  өдрөөс эхлэн 2026 оныг дуустал гэж тодорхойлсон байна. Ердөө 4
                  жил татварын хөнгөлөлт үзүүлэхээр хуульчилсан аж.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Яагаад энэ хугацааг чухалчилж яриад байна вэ гэвэл тур
                  операторуудын тайлант хугацаанд олсон орлогоос хууль хүчин
                  төгөлдөр болсон хугацаа буюу 6 дугаар сарын 23-ны өдрөөс
                  хойшхи борлуулалтын орлогод “хөнгөлөлт” эдлүүлэх ёстой юм.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Тур оператор компаниудын нягтлангууд 2023 оны санхүүгийн
                  жилийн нийт борлуулалтын орлогоосоо зөвхөн хууль хэрэгжиж
                  эхлэсэн хугацаанаас хойш олсон орлогодоо “хөнгөлөлт”
                  эдлүүлэхээр тайлагнах ёстой болно. Түүнээс өмнөх хугацааны
                  орлогод энэ хөнгөлөлтийг эдлүүлэхгүй.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  2. Дараагийн “алдаа” нь Аялал жуулчлалын тухай хуулийг
                  дагалдан гарсан Аж ахуйн нэгжийн орлогын албан татварын тухай
                  хуулинд орсон өөрчлөлт юм. ААНОАТ-ын тухай хуулиар тур
                  оператор компанийн үйл ажиллагааны орлогод ногдох албан татвар
                  хөнгөлөхөөр хуульчилжээ. (Хуулийн 22.5.12 заалт) Энэ нь тур
                  операторын 2023 оны 6 дугаар сарын 23-ны өдрөөс хойш олсон
                  орлогод ногдох албан татварыг 100 хувь хөнгөлнө гэж
                  ойлгогдоно.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Энэ заалтыг тур оператор компаниуд орлогыг нь татвараас
                  “чөлөөлсөн” гэдэг утгаар нь ойлгосон аж. Магадгүй хуулийн
                  төсөл боловсруулахдаа ингэж ойлгоход хүргэсэн байж мэднэ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  “Хөнгөлөлт” гэдэг үг бол ногдуулсан татварыг хөнгөлнө гэсэн
                  утгатай. Орлогыг хөнгөлнө гэх ойлголт байдаггүй. Орлогыг
                  чөлөөлөх гэсэн ойлголт бий. “Албан татварын хөнгөлөлт” хэмээх
                  ААНОАТ-ын тухай хуулийн 22 дугаар зүйл нь ногдуулсан татварыг
                  хэдэн хувиар хөнгөлөх тухай байдаг. Харин тур оператор
                  компаниудын “үйл ажиллагааны орлого” гэж заасан нь магадгүй
                  орлогыг нь татвараас “чөлөөлжээ” гэх ойлголтыг түгээсэн байж
                  болох юм. Орлогыг татвараас чөлөөлөх гэдэг ойлголт нь хуулийн
                  “Албан татвараас чөлөөлөх” гэсэн 21 дүгээр зүйлд хамаатай.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Тур оператор компаниудын 2023 оны 6 дугаар сарын 23-ны өдрөөс
                  эхлэн 12 дугаар сарын 31-ныг дуустал хугацааны орлогод
                  ногдуулсан албан татварыг 100 хувь хөнгөлж байна гэсэн үг.
                  Харин түүнээс өмнөх тайлант хугацааны орлогод хамаарахгүй.
                  Тэгэхээр эдгээр компанийн 2023 оны борлуулалтын орлогод ялгаа
                  гарна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Яагаад энэ ойлголтыг дахин дахин тайлбарлаад байна вэ гэвэл
                  сургалтанд суусан оролцогчдоос тайлангийн сүүлийн өдрийг
                  хүртэл маш олон асуулт ирсээр байсан учраас нэгд нэгэнгүй
                  дэлгэрэнгүй бичих шаардлагатай юм байна гэж бодоход хүргэсэн.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Хамгийн сүүлд нэг оролцогч маань “Хөнгөлөлт эдлэхийн тулд
                  ногдсон татвараа хуулийн хугацаанд бүрэн төлсөн байх
                  шаардлагатай гэнэ. Тайлангийн маягтандаа эртхэн өөрчлөлт
                  оруулаад өгсөн бол төлөх татвараа хамт төлөөд хөнгөлөлтөө
                  эдлэх байсан шүү дээ” гэж харамсангүй өнгөөр асуулт асуусан
                  юм.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Яагаад хөнгөлөлт эдлэхийн тулд татвараа заавал төлсөн байх
                  шаардлагыг татварын алба нь тавьсан юм бол гэсэн дахиад л
                  асуулттай хоцров. Татварын хуулинд хөнгөлөлт эдлэхийн тулд
                  тавигдсан шаардлагууд бий. Энэ жилийн тайлан авах сүүлийн
                  хугацааг сунгахдаа Татварын алба гэнэтийн “цочир” мэдэгдэл
                  гаргасан.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Тайлангийн сүүлийн өдөр цагаан сарын шинийн нэгэнтэй давхцаж
                  дээр нь санхүүгийн цахим тайлангийн системүүд нь шинэчлэгдсний
                  улмаас хэвийн ажиллах хүртлээ нилээн цаг хугацаа авсан.
                  Магадгүй энэ шалтгааны улмаас Сангийн яам тайлан авах хугацааг
                  сунгасан биз. Дагаад Татварын албаны тайлан авах хугацаа
                  сунгагдсан. Гэтэл Татварын алба “Тайлан авах хугацаа сунгасан
                  болохоос төлөх хугацааг сунгаагүй. Тиймээс 2024 оны 2 дугаар
                  сарын 10-ны өдөр тухайн жилд ногдуулсан татвараа бүрэн
                  төлөөгүй бол хөнгөлөлт эдлүүлэхгүй” гэсэн утга бүхий мэдэгдэл
                  гаргажээ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Татварын албаны энэ мэдэгдэл нь тун хариуцлагагүй, хууль
                  зөрчсөн, эрх мэдлээ хэтрүүлэн хэрэглэсэн санагдсан юм.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Эндээс харахад төрөөс жижиг, дунд бизнесийг дэмжихээр хуулиар
                  олгосон боломж болох “татварын хөнгөлөлт”-ийг өгөхгүй байхын
                  тулд Татварын алба эрх мэдлээ ашиглаж буй хэлбэр харагдаж
                  байна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Татвар төлөх дүн гэдэг нь тайлан өгснөөр баталгаажиж бүрэн
                  тодорхой болдог бөгөөд Татварын ерөнхий хуулиндаа “татвар
                  төлөх, тайлагнах өдөр нэг” гэж тодорхойлсон байдаг.(ТЕХ 29.2)
                  Энэ ойлголтоор олон жил тайлагнаж, төлж байсан татвар төлөгч
                  нарт хөнгөлөлт эдлэх болохоор төлөх хугацааг үгүйсгэж байгаа
                  нь хачирхалтай.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Татварын албаны шинэ удирдлага, шинэ ойлголт төлөвшүүлэхээр,
                  өөрийн эрх, сүр хүчийг үзүүлж байж мэднэ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Гэхдээ энэ шаардлага зөвхөн 300 саяаас 1.5 тэрбум төгрөг
                  хүртэл жилийн борлуулалтын орлоготой аж ахуйн нэгжийн орлогод
                  ногдуулсан татварын 90 хувийн хөнгөлөлт эдлүүлэх заалтанд
                  хамаарна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Харин тур оператор компаниудын хөнгөлөлт энэ заалтанд
                  хамаарахгүй. Тэгэхээр татварын албаны мэдээлэл өгч буй
                  байцаагч нар энэ талаарх мэдлэг дутмаг байгаа нь татвар төлөгч
                  учраа олохгүй байдалд хүргэж “дарамт” үүсгэж байна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Харин хамаарч болох нэг үндэслэл нь тур операторуудын 2023 оны
                  6 дугаар сарын 23-ны өдрөөс өмнө олсон орлого 300 саяас давсан
                  тохиолдолд л гарна. Тэгвэл эдгээр компаниуд хууль хүчин
                  төгөлдөр болохоос өмнөх орлогоо яаж тайлагнах вэ гэсэн асуулт
                  үүснэ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Энэ хугацааны орлого нь хуулинд байх бусад хөнгөлөлтийг
                  эдлэхээр тайлагнагдана. Хэрвээ тур оператор компанийн энэ
                  хугацаа хүртлэх орлого 300 сая хүртэл бол 1 хувийн татвар
                  төлнө. Үүнээс дээш орлоготой байсан бол 90 хувийн заалтаар
                  хөнгөлөлт эдлэнэ.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Хамгийн гол нь энэ хөнгөлөлтийг эдлэх боломжийг Татварын алба
                  цахим системдээ 1 дүгээр сарын сүүлч гартал оруулаагүй байв.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Хууль батлагдсны дараа холбогдох журам, аргачлал гарч,
                  тайлангийн маягтанд өөрчлөлт орох ёстой. Гэтэл энэ ажил огт
                  хийгдээгүй байсан нь дээрх хүндрэлтэй нөхцлийг үүсгэж байна
                  Өнөөдрийг хүртэл энэ журам батлагдаагүй л байна.
                </p>
                <p style={{ lineHeight: 2, justifyContent: "space-between" }}>
                  Татварын алба тайлангийн маягтандаа оруулах өөрчлөлтөө
                  батлаагүй байсан ч цахим тайлангийн системд өөрчлөлт оруулсан
                  нь системд гэнэтийн өөрчлөлт оруулснаас үүсэх гацалт, буруу
                  таталт, хөнгөлөлтийн тооцоолол буруу хийгдэх гэх мэт асуудал
                  үүссэн бололтой. Учир юу вэ гэвэл хууль нь батлагдчихаад
                  байхад хөнгөлөлт эдлэх тайлангаа тайлагнаж чадаагүйгээс
                  татварын хөнгөлөлт эдлэх эрхээ эдэлж чадахгүй байгаа олон олон
                  туроператор компаниуд байгаа бололтой.
                </p>
                <p
                  style={{
                    lineHeight: 2,
                    justifyContent: "space-between",
                  }}
                >
                  Гэтэл Монгол Улсын Засгийн газраас 2023-2025 оныг Монголд
                  зочлох жил болгон зарласан байдаг. Татварын алба энэ алдаагаа
                  эргэн харж аялал жуулчлалын салбарын аж ахуйн нэгжүүдэд
                  хуулийн дагуу татварын хөнгөлөлт эдлэх эрхийг нь олгоосой.
                </p>
                <p style={{ lineHeight: 2, textAlign: "end" }}>Ч.Батчимэг</p>
                <p
                  style={{
                    lineHeight: 2,
                    textAlign: "end",
                  }}
                >
                  Татварын зөвлөх
                </p>
              </div>
            </div>
            <div className=" mt-14">
              <h3 className=" mb-4">Хариу бичих</h3>
              <div>
                Таны мэйл хаяг харагдахгүй. Шаардлагатай талбаруудыг бөглөнө үү.
              </div>
              <ContactForm />
            </div>
          </div>

          <div className="lg:col-span-4 col-span-12">
            <div className="sidebarWrapper space-y-[30px]">
              <div className="wdiget widget_search">
                <div className="bg-[#F8F8F8] flex  rounded-md shadow-e1 items-center  py-[4px] pl-3  relative">
                  <div className="flex-1">
                    <input
                      type="text"
                      placeholder="Search keyword..."
                      className="border-none focus:ring-0 bg-transparent"
                    />
                  </div>
                  <div className="flex-none">
                    <button className="btn btn-primary">
                      <img src={search} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              {/* <BlogCategories></BlogCategories> */}
              {/* <RelatedCourses></RelatedCourses>
              <PopularTags></PopularTags> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost3;

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import ErrorPage from "./Components/Pages/404Page";
import AboutOne from "./Components/Pages/AboutOne";
// import AboutTwo from "./Components/ad/AboutTwo";
import BLogStandard from "./Components/Pages/BLogStandard";
import ContactUs from "./Components/Pages/ContactUs";
import Courses from "./Components/Pages/Courses";
import CourseSideBar from "./Components/Pages/CourseSideBar";
// import Event from "./Components/ad/Event";
// import EventSingle from "./Components/Pages/EventSingle";
import InstructorDetails from "./Components/Pages/InstructorDetails";
import InstructorOne from "./Components/Pages/InstructorOne";
// import InstructorTwo from "./Components/ad/InstructorTwo";
import SingleCourse from "./Components/Pages/SingleCourse";
import Thanks from "./Components/Pages/Thanks";
import Register from "./Components/Pages/Register";
import Reservation from "./Components/Pages/reservation/Reservation";
import SingleBlog from "./Components/Pages/blogs/SingleBlog";
import SingleBlog2 from "./Components/Pages/blogs/SingleBlog2";
import ReservationConfirm from "./Components/Pages/reservation/ReservationConfirm";
import Payment from "./Components/Pages/Payment";
import Feature from "./Components/Feature";
import SingleBlog3 from "./Components/Pages/blogs/SingleBlog3";
import SingleBlog4 from "./Components/Pages/blogs/SingleBlog4";
import SingleBlog5 from "./Components/Pages/blogs/SingleBlog5";
import Jobs from "./Components/Pages/Jobs";
import SingleBlog6 from "./Components/Pages/blogs/SingleBlog6";
import TempRegister from "./Components/Pages/TempRegister";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<Navigate to={"/home"} />} />
          <Route path="/home" element={<HomeOne />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/reservation/confirm" element={<ReservationConfirm />} />
          <Route path="/about" element={<AboutOne />} />
          {/* <Route path="/about-two" element={<AboutTwo />} /> */}
          <Route path="/instructor" element={<InstructorOne />} />
          {/* <Route path="/instructor-two" element={<InstructorTwo />} /> */}
          <Route path="/instructor-details" element={<InstructorDetails />} />
          {/* <Route path="/event" element={<Event />} />
          <Route path="/event-single" element={<EventSingle />} /> */}
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses-sidebar" element={<CourseSideBar />} />
          <Route path="/single-course" element={<SingleCourse />} />
          <Route path="/blogs" element={<BLogStandard />} />
          <Route path="/blogs/1" element={<SingleBlog />} />
          <Route path="/blogs/2" element={<SingleBlog2 />} />
          <Route path="/blogs/4" element={<SingleBlog4 />} />
          <Route path="/blogs/5" element={<SingleBlog5 />} />
          <Route path="/blogs/6" element={<SingleBlog6 />} />
          <Route path="/single-blog2" element={<SingleBlog2 />} />
          <Route path="/single-blog3" element={<SingleBlog3 />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/reg" element={<TempRegister />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

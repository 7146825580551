/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import aboutOne from "../../assets/images/all-img/about1.png";
import { useState } from "react";
import callApi from "../../services/api";

const TempRegister = () => {
  const [form] = Form.useForm();
  const [loginShow, setLoginShow] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const body = {
      lastname: values.lastname,
      firstname: values.firstname,
      email: values.email,
      phone: values.phone,
      aname: values.aname,
      aregister: values.aregister,
    };
    const response = await callApi(`/temp-users`, {
      method: "POST",
      body: JSON.stringify(body),
    });
    console.log("response: ", response);
    if (response.user_id) {
      notification.success({
        message: "Таны бүртгэл амжилттай боллоо.",
      });
      window.location.reload();
    }
    console.log("response: ", response);
  };

  const onFinishLogin = async (values) => {
    try {
      const response = await callApi(`/users/login`, {
        method: "POST",
        body: JSON.stringify({
          phone: values.loginPhone,
          password: values.loginPassword,
        }),
      });
      if (response.data) {
        localStorage.setItem("accessToken", response.token);
        const jsonString = JSON.stringify(response.data);
        localStorage.setItem("user", jsonString);
        notification.success({ message: "Амжилттай нэвтэрлээ." });
        navigate("/");
      }
    } catch (error) {
      notification.error({ message: "Нэвтрэх нэр, нууц үг буруу байна." });
    }
  };

  const cancel = () => {
    navigate("/");
  };
  const callLogin = () => {
    console.log("login");
    setLoginShow(true);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs section-padding">
        <Card style={{ width: 1600, margin: "0 auto", display: "flex" }}>
          <Row justify="center" align="middle">
            <Col span={12}>
              {loginShow === false && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: 700,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 100,
                      marginLeft: 30,
                    }}
                  >
                    <h3 className="shape-bg">Сургалтанд</h3>
                    <h3 style={{ marginLeft: 20, marginBottom: 5 }}>
                      бүртгүүлэх
                    </h3>
                  </div>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    style={{
                      alignContent: "left",
                    }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name={["lastname"]}
                      label="Овог"
                      rules={[
                        {
                          required: true,
                          message: "Овог оруулна уу",
                        },
                      ]}
                      style={{
                        marginTop: 40,
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={["firstname"]}
                      label="Нэр"
                      rules={[
                        {
                          required: true,
                          message: "Нэр оруулна уу",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={["email"]}
                      label="Email хаяг"
                      rules={[
                        {
                          type: "email",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Утасны дугаар"
                      rules={[
                        {
                          required: true,
                          message: "Утасны дугаар оруулна уу",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <div style={{ marginLeft: 24, marginBottom: 10 }}>
                      Ебаримт авах аж ахуйн нэгж
                    </div>
                    <Form.Item name="aname" label="ААН нэр:">
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="aregister" label="Регистрийн дугаар">
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <div
                      className="md:flex  md:space-x-4 space-y-3 md:space-y-0"
                      style={{ width: 400, marginLeft: 300 }}
                    >
                      <Button
                        type="primary"
                        className="btn btn-primary"
                        block
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Бүртгүүлэх
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Col>

            <Col span={12} style={{ marginTop: 100 }}>
              <img src={aboutOne} alt="aboutOne" className="ml-20" />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default TempRegister;

import {
  c1,
  c2,
  c3,
  c4,
  counter1,
  counter2,
  counter3,
  counter4,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  // team9,
  // team10,
  // team11,
  team12,
  team13,
  team14,
  team15,
  team16,
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 82,
    sector: "Бүртгэлтэй хэрэглэгчид",
  },
  {
    icon: counter2,
    num: 32,
    sector: "Нийт хичээл",
  },
  {
    icon: counter3,
    num: 215,
    sector: "Ганцаарчилсан сургалт",
  },
  {
    icon: counter4,
    num: 18,
    sector: "Багш нар",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: [0, "tax", "design"],
    post: "НӨАТ",
    title: "НӨАТ-ын тайлан илгээх ТТ-03",
    price: "20000₮",
    img: c1,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
  {
    id: [0, "tax", "finance"],
    post: ["НӨАТ"],
    price: "Үнэгүй",
    title: "НӨАТ-ын  нэгтгэл хүснэгт гаргах",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
  {
    id: [0, "marketing", "design"],
    post: "НӨАТ",
    price: "20000₮",
    title: "НЭМЭГДСЭН ӨРТГИЙН АЛБАН ТАТВАР",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
  {
    id: [0, "marketing"],
    post: "Тайлан",
    price: "20000₮",
    title: "ЖИЛД ХЭДЭН УДАА ТАЙЛАН ИЛГЭЭХ ВЭ?",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
  {
    id: [0, "finance", "design"],
    post: "Тайлан",
    title: " ААНОАТ-ын талаар",
    price: "20000₮",
    img: c1,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
  {
    id: [0, "design", "finance"],
    post: "Татвар",
    title: "Татвар төлөгчөөр хэрхэн бүртгүүлэх вэ?",
    price: "Үнэгүй",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 хичээл",
  },
];

export const topicsData = [
  { logo: t1, title: "Data Science", quantity: "68 Courses" },
  { logo: t2, title: "UI/UX Design", quantity: "UI/UX Design" },
  { logo: t3, title: "Modern Physics", quantity: "68 Courses" },
  { logo: t4, title: "Music Production", quantity: "45 Courses" },
  { logo: t5, title: "Data Science", quantity: "68 Courses" },
  { logo: t6, title: "Finances", quantity: "68 Courses" },
  { logo: t7, title: "Global Science", quantity: "75 Courses" },
  { logo: t8, title: "Game Design", quantity: "12 Courses" },
];

export const teamData = [
  {
    img: team1,
    name: "Цэен",
    firstname: "ЭНХНАРАН",
    title:
      "Үүсгэн байгуулагч, Гүйцэтгэх захирал Татварын мэргэшсэн зөвлөх, мэргэшсэн нягтлан бодогч",
  },
  {
    img: team3,
    name: "Чунсрайхачин",
    firstname: "НАРАНТУЯА",
    title: "Татварын итгэмжит зөвлөх, Татварын мэргэшсэн зөвлөх",
  },
  {
    img: team4,
    name: "Лодой",
    firstname: "БАТЦЭЦЭГ",
    title: "Татварын мэргэшсэн зөвлөх, мэргэшсэн нягтлан бодогч",
  },
  {
    img: team12,
    name: "Өлзийбаяр",
    firstname: "САРАНТУЯА",
    title: "Санхүүгийн зөвлөх, нягтлан бодогч /хятад хэл/ ",
  },

  {
    img: team14,
    name: "Лхамхүү",
    firstname: "ХОС-ЭРДЭНЭ",
    title: "Бизнесийн зөвлөх",
  }, {
    img: team2,
    name: "Чулуунбат",
    firstname: "БАТЧИМЭГ",
    title: "Татварын зөвлөх",
  }, {
    img: team13,
    name: "Жанцанбал",
    firstname: "БОЛОРМАА",
    title: "Татварын зөвлөх, мэргэшсэн нягтлан бодогч",
  },
  {
    img: team15,
    name: "Дагвадорж",
    firstname: "ЛХАГВАТУНГАЛАГ",
    title: "Татварын жинхэнэ зөвлөх",
  },

];
export const teamData2 = [


  {
    img: team5,
    name: "Жамсрандорж",
    firstname: "ДЭЛГЭРСАЙХАН",
    title: "Эдийн засагч, Зөвлөх",
  },
  {
    img: team6,
    name: "Ганбаатар",
    firstname: "ОРХОН",
    title: "Хуулийн зөвлөх, Өмгөөлөгч",
  },
  {
    img: team8,
    name: "Даваа",
    firstname: "БАЯСГАЛАН",
    title: "Эдийн засагч, Мэргэшсэн нягтлан бодогч",
  },
  {
    img: team16,
    name: "Мөнхбат",
    firstname: "НАМУУН-ЭРДЭНЭ",
    title: "Оффис менежер",
  },
  // {
  //   img: team9,
  //   name: "Хөхөө",
  //   firstname: "НАРАНЧИМЭГ",
  //   title: "Татварын итгэмжит зөвлөх, Татварын мэргэшсэн зөвлөх",
  // },
];
export const teamData3 = [
  // {
  //   img: team8,
  //   name: "Даваа",
  //   firstname: "БАЯСГАЛАН",
  //   title: "Эдийн засагч, Мэргэшсэн нягтлан бодогч",
  // },
  // {
  //   img: team10,
  //   name: "Галдан",
  //   firstname: "БИЛЭГ-ӨРНӨХ",
  //   title: "Мэргэшсэн нягтлан бодогч, зөвлөх",
  // },
  // {
  //   img: team11,
  //   name: "Баасанбат",
  //   firstname: "ТҮВШИНЖАРГАЛ",
  //   title: "Эдийн засагч, зөвлөх",
  // },
  // {
  //   img: team7,
  //   name: "Гүнгээбуу",
  //   firstname: "ЛХАМ-ОТГОН",
  //   title: "Мэргэшсэн нягтлан бодогч",
  // },
];
export const instructorData = [
  { img: team5, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team6, name: "Daniel Steven", title: "Developer" },
  { img: team7, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team8, name: "Selina Gomez", title: "UI/UX Designer" },
];

export const blogsData = [
  {
    img: c1,
    type: "татвар",
    position: "admin",
    date: "may 29, 2024",
    title: "Татварын зөвлөх үйлчилгээний талаар",
  },
  {
    img: c2,
    type: "хууль",
    position: "admin",
    date: "jun 12, 2024",
    title: "Гадаадын хөрөнгө оруулалтын татварын орчин",
  },
  {
    img: c3,
    type: "эрх зүй",
    position: "admin",
    date: "jun 10, 2024",
    title: "Олон улсын татварын тухай",
  },
];

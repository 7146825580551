/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import aboutOne from "../../assets/images/all-img/about1.png";
import { useState } from "react";
import callApi from "../../services/api";

const RegisterDetails = () => {
  const [form] = Form.useForm();
  const [loginShow, setLoginShow] = useState(false)
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const body = {
      lastname: values.lastname,
      firstname: values.firstname,
      email: values.email,
      phone: values.phone,
      password: values.password
    }
    const response = await callApi(`/users`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
    if(response.user_id) {
      notification.success({
        message: "Таны бүртгэл амжилттай боллоо."
    });
    window.location.reload();
    }
    console.log('response: ', response)
  };

  const onFinishLogin = async (values) => {
    try {
      const response = await callApi(`/users/login`, {
        method: 'POST',
        body: JSON.stringify({phone:values.loginPhone, password:values.loginPassword})
      });
      if (response.data) {
        localStorage.setItem('accessToken', response.token);
        const jsonString = JSON.stringify(response.data);
        localStorage.setItem('user', jsonString);
        notification.success({message:'Амжилттай нэвтэрлээ.'})
        navigate('/')
      } 
    } catch (error) {
      notification.error({message:'Нэвтрэх нэр, нууц үг буруу байна.'})
    }
  };

  const cancel = () => {
    navigate('/');
  }
  const callLogin = () => {
    console.log('login')
    setLoginShow(true);
  }
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs section-padding">
        <Card style={{ width: 1600, margin: '0 auto', display: 'flex' }}>
          <Row justify="center" align="middle">
            <Col span={12} >
              {loginShow === false && (
                <>
                  <h3 className="shape-bg" style={{ marginLeft: 400 }}>Бидэнтэй нэгдэх</h3>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    style={{
                      alignContent: "left"
                    }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name={['lastname']}
                      label="Овог"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{
                        marginTop: 40
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['firstname']}
                      label="Нэр"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['email']}
                      label="Email"
                      rules={[
                        {
                          type: 'email',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone number!',
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <div className="md:flex  md:space-x-4 space-y-3 md:space-y-0" >
                      <Button type="primary" className="btn btn-primary" block htmlType="submit" style={{ marginLeft: 90 }}>
                        Бүртгүүлэх
                      </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 20, marginLeft: 90 }}>Эсвэл</div>
                    <div className="md:flex  md:space-x-4 space-y-3 md:space-y-0" >
                      <Button type="primary" className="btn btn-pink" block onClick={callLogin} style={{ marginLeft: 90,color:"#29c8e0" }}>
                        Нэвтрэх
                      </Button>
                    </div>
                  </Form>
                </>
              )}
              {loginShow === true && (
                <>
                  <h3 className="shape-bg" style={{ marginLeft: 400, marginBottom: 60 }}>Нэвтрэх</h3>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinishLogin}
                    style={{
                      alignContent: "left"
                    }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="loginPhone"
                      label="Утасны дугаар"
                      rules={[
                        {
                          required: true,
                          message: 'Утасны дугаараа оруулна уу!',
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="loginPassword"
                      label="Нууц үг"
                      rules={[
                        {
                          required: true,
                          message: 'Нууц үгээ оруулна уу!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <div className="md:flex  md:space-x-4 space-y-3 md:space-y-0" >
                      <Button type="primary" className="btn btn-primary" block htmlType="submit" style={{ marginLeft: 90 }}>
                        Нэвтрэх
                      </Button>
                    </div>
                    <div className="md:flex  md:space-x-4 space-y-3 md:space-y-0" style={{ display: "flex", justifyContent: "center", }} onClick={cancel}>
                      <Button type="primary" block className="btn btn-pink" style={{ marginTop: 20, marginLeft: 90 }}>
                        Буцах
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Col>

            <Col span={12}>
              <img src={aboutOne} alt="aboutOne" className="ml-20" />
            </Col>
          </Row>
        </Card>
      </div >
    </>
  );
};

export default RegisterDetails;

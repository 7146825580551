// components/AnnouncementBanner.js
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import banner from "../../assets/images/banner/poster_v6.png";
// import { useNavigate } from "react-router-dom";

const AnnouncementBanner = () => {
  // const navigate = useNavigate();
  const [isVisible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
  };

  // const callTemp = () => {
  //   navigate("/reg");
  // };

  return (
    <div
      className="banner-box "
      style={{ display: `${isVisible === true ? "flex" : "none"}` }}
    >
      <Button className="close-button" onClick={handleClose}>
        x
      </Button>
      <img src={banner} alt="Announcement" style={styles.image} />
      {/* <Button className="reg-button" onClick={callTemp}>
        Бүртгүүлэх
      </Button> */}
    </div>
  );
};

const styles = {
  image: {
    width: "100%",
    height: "100%",
    // maxHeight: "650px",
  },
  closeButton: {
    // marginLeft: "auto",
  },
};

export default AnnouncementBanner;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCN from "../src/locales/cn/translation.json";
import translationMN from "../src/locales/mn/translation.json";
// // Import your translation files
// import translationEN from "./locales/en/translation.json";
// import translationZH from "./locales/zh/translation.json";

const resources = {
  mn: {
    translation: translationMN,
  },
  cn: {
    translation: translationCN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "mn",
  fallbackLng: "mn",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
